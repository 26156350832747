import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Hero'
import ArticleIntro from 'components/Article/ArticleIntro'
import ArticleList from 'components/ArticleList'
import renderContent from 'utils/componentRenderer'
import {
  filterRepeatedContent,
  decorateListicleContent,
  groupContentTypes,
} from 'utils/contentUtils'
import { Spacing50 } from 'styles/sharedStyle'
import GA4 from 'utils/GA4'

const nonRepeatedContentTypes = [
  'ContentfulCompCarouselFullBleedCar03',
  'ContentfulCompCarouselArticleCar01',
]

const renderArticleContent = (content, pageProps) => {
  const filteredContent = decorateListicleContent(
    groupContentTypes(filterRepeatedContent(content, nonRepeatedContentTypes))
  )
  return renderContent(filteredContent, pageProps)
}

const WhatsOnPage = (props) => {
  const {
    data,
    pageContext: { pageLevel },
  } = props
  const {
    page,
    page: {
      hero,
      standFirst,
      content,
      topPicks,
      locale,
      village,
      jsonLdSchema,
    },
  } = data

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape

  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
        name: media.name,
      }
    : {}
  const heroProps = hero
    ? {
        image: heroImage,
        eyebrow: hero.eyebrow,
        content: hero.headline,
        logo: page.brandLogo,
        video: heroVideo,
        opacity: hero.opacity,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  useEffect(() => {
    GA4('whats_on', { village_name: page?.village?.name })
  }, [])

  const topPickItems =
    topPicks && topPicks.items
      ? topPicks.items.reduce((arr, item) => {
          switch (item.__typename) {
            case 'ContentfulPageTemplateEditorialT08': {
              arr.push({
                ...item.hero,
                slug: item.slug || '',
                publishDate: item.published,
                eyebrow:
                  item.hero && item.hero.eyebrow
                    ? item.hero.eyebrow
                    : topPicks.articleLabel || '',
                pageType: item.pageType,
              })
              break
            }
            case 'ContentfulPageTemplateBrandDetailT06': {
              arr.push({
                ...item.hero,
                slug: item.slug || '',
                headline: item.name,
                eyebrow: topPicks.brandLabel || '',
                pageType: item.pageType || { slug: 'brands' },
              })
              break
            }
            case 'ContentfulPageTemplateSectionDetailT03': {
              const event = item.content.find(
                (pageContent) =>
                  pageContent.__typename === 'ContentfulEntityEventEnt04'
              )

              arr.push({
                ...item.hero,
                publishDate: event ? event.eventRunStartDate : '',
                slug: item.slug || '',
                pageType: item.pageType,
              })
              break
            }
            case 'ContentfulPageTemplateRestaurantDetailT07': {
              arr.push({
                ...item.hero,
                slug: item.slug || '',
                headline: item.name,
                eyebrow: topPicks.dineLabel || '',
                pageType: item.pageType || { slug: 'dine' },
              })
              break
            }
            case 'ContentfulPageTemplateLifestyleT17': {
              arr.push({
                ...item.hero,
                slug: item.slug || '',
                publishDate: item.published || '',
                headline: item.pageTitle || '',
                eyebrow: item.pageSubtitle || '',
                pageType: item.pageType,
              })
              break
            }
            default:
              break
          }
          return arr
        }, [])
      : []

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && <Hero {...heroProps} />}

      <Spacing50 />

      <ArticleIntro copy={standFirst ? standFirst.copy : ''} />

      {topPickItems.length > 0 && (
        <ArticleList
          articles={topPickItems.map(
            ({
              headline: itemHeading,
              media: itemMedia,
              eyebrow: itemEyebrow,
              publishDate,
              pageType,
              slug,
            }) => ({
              category: itemEyebrow,
              image:
                itemMedia &&
                itemMedia[0] &&
                (itemMedia[0].videoPlaceholder || itemMedia[0]),
              heading: itemHeading,
              publishDate: publishDate || '',
              pageType,
              slug,
              locale,
              village,
              villageName:
                pageLevel === 'collection'
                  ? 'The Bicester Collection'
                  : page?.village?.name,
            })
          )}
          eyebrow={topPicks.eyebrow}
          header={topPicks.headline}
          loadMore={topPicks.loadMoreLabel}
        />
      )}

      {content && content.length && renderArticleContent(content, props)}
    </Layout>
  )
}

WhatsOnPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    pageLevel: PropTypes.string,
  }).isRequired,
}

export const whatsOnPageQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $collectionId: String
    $pageTypeSlug: String
    $villageSlug: String
    $needContactIcons: Boolean = false
  ) {
    page: contentfulPageTemplateWhatsOnT09(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      locale: node_locale
      village {
        name
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
          locale: node_locale
        }
        openingHours {
          ...villageOpeningHours
        }
        labels: label_brand_details_lab01 {
          readMoreLabel
          readLessLabel
          contactDetailsHeader
          ...virtualShoppingIconsAndLabelsQuery
          onlineLabel
          viewOnMapLabel
        }
        defaultLocale
        openingStatusLabel
      }
      hero {
        headline
        eyebrow
        opacity
        ...heroMediaQuery
      }
      standFirst {
        copy: standFirst
      }
      topPicks {
        brandLabel
        articleLabel
        dineLabel
        loadMoreLabel
        eyebrow
        headline
        items {
          __typename
          ... on Node {
            ... on ContentfulPageTemplateEditorialT08 {
              hero {
                headline
                eyebrow
                ...heroMediaQuery
              }
              published(formatString: "D MMMM YYYY")
              slug
              pageType {
                slug
              }
              village {
                slug
              }
            }
            ... on ContentfulPageTemplateBrandDetailT06 {
              hero {
                headline
                eyebrow
                ...heroMediaQuery
              }
              name
              slug
              pageType {
                slug
              }
              village {
                slug
              }
            }
            ... on ContentfulPageTemplateSectionDetailT03 {
              hero {
                headline
                eyebrow
                ...heroMediaQuery
              }
              slug
              pageType {
                slug
              }
              village {
                slug
              }
              content {
                __typename
                ... on Node {
                  ... on ContentfulEntityEventEnt04 {
                    eventRunStartDate(formatString: "D MMMM YYYY")
                  }
                }
              }
            }
            ... on ContentfulPageTemplateRestaurantDetailT07 {
              hero {
                headline
                eyebrow
                ...heroMediaQuery
              }
              name
              slug
              pageType {
                slug
              }
              village {
                slug
              }
            }
            ... on ContentfulPageTemplateLifestyleT17 {
              hero {
                headline
                eyebrow
                ...heroMediaQuery
              }
              published: publishDate(formatString: "D MMMM YYYY")
              slug
              pageTitle
              pageSubtitle
              pageType {
                slug
              }
              village {
                slug
              }
            }
          }
        }
      }
      content {
        __typename
        ... on Node {
          ...multipleComponentsT09
        }
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          date(formatString: "DD-MM-YYYY")
          openingTime
          closingTime
          closedFlag
          label
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    collectionPage: contentfulPageTemplateHomeT01(id: { eq: $collectionId }) {
      header {
        ...header
      }
      footer {
        ...footer
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default WhatsOnPage
